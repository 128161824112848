<main class="layout">
  <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
  <app-lock-screen *ngIf="showLockScreen"></app-lock-screen>
  <header class="layout__header" *ngIf="isAuthenticated && !fullLayoutEnabled">
    <app-toolbar></app-toolbar>
  </header>
  <section class="layout__content">
    <aside class="layout__nav" *ngIf="isAuthenticated && !fullLayoutEnabled">
      <app-nav-menu></app-nav-menu>
      <button mat-icon-button *ngIf="helpersService.isDesktop && appService.state.showBodyControls"
        (click)="toggleSideNav()" class="toggle-body"
        [matTooltip]="appService.state.navState === 'closed' ? 'open menu' : 'close menu'">
        <icon name="chevron_left" class="flip-rtl" [ngClass]="{'rotate-180': appService.state.navState === 'closed'}">
        </icon>
      </button>
    </aside>
    <div class="layout__body" [ngClass]="{'p-0': !isAuthenticated || fullLayoutEnabled}">
      <app-loading></app-loading>
      <router-outlet></router-outlet>
    </div>
    <div class="network-status offline" *ngIf="!onlineOfflineService.isOnlineValue">
      {{ "youAreOffline" | translate }}
    </div>
    <div class="layout__actions" *ngIf="appService.isQuickActionsOpend">
      <app-quick-actions></app-quick-actions>
    </div>
  </section>
  <div class="chat-box">
    <button mat-fab id="edarachat" class="button--tertiary">
      <span *ngIf="intercomMessages" id="edarachatnotify">
        {{ intercomMessages }}
      </span>
      <icon name="chat_bubble"></icon>
    </button>
  </div>
</main>