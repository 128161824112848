import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { UpdateAppVersionDialogComponent } from '@core/components/update-app-version-dialog/update-app-version-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  constructor(private httpClient: HttpClient,
   private dialog : MatDialog) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 10 minutes
   */
  public initVersionCheck(url: string, frequency = 1000 * 60 * 10) {
    if (environment.production) {
      setInterval(() => this.checkVersion(url), frequency);
    }
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': 'No-Auth' }) };

    // timestamp these requests to invalidate caches
    this.httpClient.get(url + '?t=' + new Date().getTime(), httpOptions).subscribe({
      next: (response: any) => {
        const hash = response.hash;
        const enforceUpdate = response.enforceUpdate;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        // If new version, do something
        if (hashChanged) {
          this.clearCache();
          this.dialog.open(UpdateAppVersionDialogComponent, {
            width: '750px',
            disableClose: true,
            data: { enforceUpdate: enforceUpdate }
          })
            .afterClosed().subscribe({
              next: async (result: boolean) => {
                if (result === true) {
                  await this.uninstallServiceWorker();
                  location.reload();
                  // Store the new hash so we wouldn't trigger versionChange again
                  // only necessary in case you did not force refresh
                  this.currentHash = hash;
                }
              }
            });
        }
      },
      error: (err: any) => {
        console.error(err, 'Could not get version');
      }
    });
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }

  private clearCache() {
    caches.keys().then((cacheNames: string[]) => {
      return Promise.all(
        cacheNames.map((cacheName: string) => {
          return caches.delete(cacheName).then((result) => console.log(`Cache ${cacheName} deleted: ${result}`));
        })
      );
    });
  }

  private async uninstallServiceWorker() {
    return navigator.serviceWorker.getRegistrations().then((registrations: ReadonlyArray<ServiceWorkerRegistration>) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }

}
